<template>
  <div class="w-full height-container-mapbox">
    <div id="mapbox"></div>
  </div>
</template>
<script>
import Mapbox from '@/mixins/map/mapbox'
import { mapGetters } from 'vuex'
import parcelle from '@/mixins/parcelle'
import batiment from '@/mixins/batiment'

export default {
  data() {
    return {
      feature_batis_3d_exist: [],
      markers_length_walls: [],
      markers_height_bati: [],
    }
  },
  computed: {
    ...mapGetters({
      features_autour: 'parcelle/features_autour',
      parcelles_autour: 'parcelle/parcelles_autour',
      current_parcelle: 'parcelle/current_parcelle',
      parcelles_voisines: 'parcelle/parcelles_voisines',
      map: 'map/map',
      address: 'address/address',
      current_batiments: 'parcelle/current_batiments',
      batiment_selected: 'parcelle/batiment_selected',
      parcelles_selected: 'parcelle/parcelles_selected',
    }),
  },
  mixins: [Mapbox, parcelle, batiment],
  mounted() {
    this.loadMap()
      ? this.loadDataToMap()
      : this.map.on('load', this.loadDataToMap)
  },
  methods: {
    loadDataToMap() {
      this.map.setMaxZoom(19)
      let fc_own_parcelles = this.$turf.featureCollection(
        this.parcelles_selected,
      )

      this.fitBoundsGeojsonInMap(fc_own_parcelles)
      this.addParcellesAutour(this.parcelles_autour)
      let options_parcelles = this.copyObject(this.options_polygone)
      options_parcelles.color = this.green_parcelle_selected
      options_parcelles.opacity = 0
      options_parcelles.is_multi_geojson = true
      this.addPolygonsToMap(
        this.own_parcelles,
        this.source_layers_all_parcelle,
        options_parcelles,
      )
      if (this.current_batiments.length) {
        this.map.on('click', this.addlistenerOnClickBatiment)
      }
      this.map.on('render', this.addbuilding3D)
    },
    getBatiExteriorPieces(own_parcelles) {
      let batis_real = []
      this.getFeaturesExist(this.features_autour, own_parcelles).forEach(
        (feature) => {
          batis_real.push(...feature.batiments)
        },
      )
      try {
        let list_polygones_batis = []
        batis_real.forEach((bati) => {
          bati.geometry.coordinates.forEach((coord) => {
            list_polygones_batis.push(
              this.$turf.polygon(this.getCoordinates([coord])),
            )
          })
        })
        var union_parcelles = this.$turf.union(...own_parcelles)
        var union_batis = this.$turf.union(...list_polygones_batis)

        var difference = this.$turf.difference(union_batis, union_parcelles)
        return difference
      } catch {
        return null
      }
    },
    addbuilding3D() {
      this.map.addSource(this.source_layers_building_3d[0], {
        type: 'vector',
        tiles: [process.env.VUE_APP_BATI_GEO_PROMY],
        minzoom: 12,
        maxzoom: 14,
      })
      this.map.addLayer({
        id: this.source_layers_building_3d[1],
        type: 'fill-extrusion',
        'source-layer': 'BATIMENT',
        source: this.source_layers_building_3d[0],
        minzoom: 13,
        paint: {
          'fill-extrusion-color': '#aaa',
          'fill-extrusion-height': 0,
          'fill-extrusion-base': 0,
          'fill-extrusion-opacity': 0.8,
        },
      })

      this.map.off('render', this.addbuilding3D)
      this.map.on('idle', this.getDataBuilding)
    },
    getDataBuilding() {
      this.map.setMaxZoom(22)
      this.batis_exterior_pieces = []
      this.feature_batis_3d_exist = []
      this.map.setMaxZoom(22)
      let union_parcelles = this.$turf.union(...this.own_parcelles)
      let bbox = this.$turf.bbox(union_parcelles)
      const boundingBox = new this.$mapboxgl.LngLatBounds([
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]],
      ])

      var features_batis = this.map.queryRenderedFeatures(
        [
          this.map.project(boundingBox.getNorthEast()),
          this.map.project(boundingBox.getSouthWest()),
        ],
        {
          layers: [this.source_layers_building_3d[1]],
        },
      )
      features_batis.forEach((feature_bati) => {
        let inersect_bati_with_parcelles = this.$martinez.intersection(
          union_parcelles.geometry.coordinates,
          feature_bati.geometry.coordinates,
        )
        if (
          inersect_bati_with_parcelles &&
          inersect_bati_with_parcelles.length
        ) {
          let union_parcelles_with_buffer = this.$turf.buffer(
            union_parcelles,
            0.0005,
          )
          let diff_batis_parcelles = this.$turf.difference(
            feature_bati,
            union_parcelles_with_buffer
              ? union_parcelles_with_buffer
              : union_parcelles,
          )
          if (
            diff_batis_parcelles &&
            this.$turf.area(diff_batis_parcelles) > 15
          ) {
            this.batis_exterior_pieces.push(diff_batis_parcelles)
          }
          this.feature_batis_3d_exist.push(feature_bati)
        }
      })
      this.current_batiments.forEach((bati, index) => {
        bati.properties.id = index
        bati.properties.render_min_height = 0
        let area_intersect = -1
        this.feature_batis_3d_exist.forEach((feature) => {
          try {
            let inersect_bati = this.$martinez.intersection(
              feature.geometry.coordinates,
              bati.geometry.coordinates,
            )
            let poly = null
            if (inersect_bati) {
              if (inersect_bati.length > 1) {
                poly = this.$turf.multiPolygon(
                  this.getCoordinates(inersect_bati),
                )
              } else {
                poly = this.$turf.polygon(this.getCoordinates(inersect_bati))
              }
              let new_area_intersect = this.$turf.area(poly)
              if (new_area_intersect > area_intersect) {
                area_intersect = new_area_intersect
                bati.properties.hauteur = feature.properties.HAUTEUR
                bati.properties.nombre_d_etages = feature.properties.NB_ETAGES
              }
            }
          } catch (e) {
            console.log(e)
          }
        })
      })
      this.displayLengthWallsAndHeightBatisOnMap()
      this.addSourceAndLayerBuilding3D()
    },
    displayLengthWallsAndHeightBatisOnMap() {
      if (this.current_batiments.length) {
        this.markers_length_walls = this.getAndAddMakerslengthsWallsToMap(
          this.getTailleMurs(this.$turf.union(...this.current_batiments)),
          this.markers_length_walls,
          false,
        )
        this.map.on('zoom', this.showHiddenMarkerAfterDebounce)

        var popup = new this.$mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        })
        this.map.on('mousemove', this.source_layers_batiments[1], (e) => {
          if (this.getNestedObject(e.features[0], 'properties', 'hauteur')) {
            popup
              .setLngLat([e.lngLat.lng, e.lngLat.lat])
              .setHTML(
                `<span class='py-4 px-2 '> Hauteur : ${e.features[0].properties.hauteur} m </span>`,
              )

              .addTo(this.map)
          }
        })

        this.map.on('mouseleave', this.source_layers_batiments[1], function () {
          popup.remove()
        })
      }
    },
    showHiddenMarkerAfterDebounce: _.debounce(function () {
      const currentZoom = this.map.getZoom()
      ;[...this.markers_height_bati, ...this.markers_length_walls].forEach(
        (marker) => {
          if (currentZoom > 16) marker.getElement().style.display = 'block'
          else marker.getElement().style.display = 'none'
        },
      )
    }, 500),
    addSourceAndLayerBuilding3D() {
      this.addSourceLayersMultiGeojsonToMap(
        this.current_batiments,
        this.blue,
        this.source_layers_batiments,
        true, // is 3D
      )
      this.addSourceLayersMultiGeojsonToMap(
        this.batis_exterior_pieces,
        '#aaa',
        this.source_layers_batiments_exterior_pieces,
        true, // is 3D
        0.87,
      )
      this.map.addLayer({
        id: this.source_layers_building_3d[2],
        type: 'line',
        source: this.source_layers_batiments[0],
        paint: {
          'line-width': 2,
          'line-color': '#00C9BC',
          'line-opacity': 0.3,
        },
      })
      if (this.feature_batis_3d_exist.length) {
        this.map.setFilter(this.source_layers_building_3d[1], [
          'match',
          ['get', 'ID'],
          [
            ...new Set(
              this.feature_batis_3d_exist.map((fe) => fe.properties.ID),
            ),
          ],
          false,
          true,
        ])
      }
      this.map.setPaintProperty(
        this.source_layers_building_3d[1],
        'fill-extrusion-height',
        ['get', 'HAUTEUR'],
      )

      this.map.off('idle', this.getDataBuilding)
      this.map.easeTo({ pitch: 50 })
    },
    addlistenerOnClickBatiment(e) {
      if (this.current_batiments.length > 1) {
        var features = this.map.queryRenderedFeatures(e.point, {
          layers: [this.source_layers_batiments[1]],
        })
        if (this.getNestedObject(features[0], 'properties', 'id_parcelles')) {
          let current_bati = this.current_batiments[features[0].id]
          let isSameBati = this.isSameBati(this.batiment_selected, current_bati)
          this.setLayerSelectedBatiment(current_bati, isSameBati)
          this.$store.commit(
            'parcelle/BATIMENT_SELECTED',
            isSameBati ? null : current_bati,
          )
        }
      }
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.removeMarkers([
        ...this.markers_length_walls,
        ...this.markers_height_bati,
      ])
      this.map.off('idle', this.getDataBuilding)
      this.map.off('load', this.loadDataToMap)
      this.map.off('click', this.addlistenerOnClickBatiment)
      this.map.off('render', this.addbuilding3D)
    }
  },
}
</script>
<style lang="scss">
#container_batiments {
  .mapboxgl-popup-content {
    border-radius: 0px !important;
  }
}
</style>
